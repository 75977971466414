import { useSelector } from "react-redux";
import { AppState } from "../../redux/store";
import { CurrentUserState } from "../../redux/c-user.slice";
import { useEffect, useRef, useState } from "react";
import { ClientUserService } from "../../services/modules/users/client-user.service";
import { useNavigate } from "react-router-dom";
import UserProfileComponent from "../user-profile";
import { OverlayPanel } from "primereact/overlaypanel";
import { Button } from "primereact/button";
import { Toolbar } from "primereact/toolbar";
import { IconTypes, iconTypeToPrimeIcon } from "../../helpers/icon";
import translate from "../../common/translate";
import { HeaderAction, NavBarState } from "../../redux/nav-bar-slice";
import { Menu } from "primereact/menu";
import { MenuItem, MenuItemCommandEvent } from "primereact/menuitem";
import BreadCrumbs from "../breadcrumbs";

export default function AppHeader() {
  const _currentUser = useSelector<AppState>(
    (state) => state.currentUser
  ) as CurrentUserState;
  const opProfile = useRef<OverlayPanel>(null);
  const [_userName, setUserName] = useState<string>("");
  const navigate = useNavigate();
  const [_menuItems, setMenuItems] = useState<MenuItem[]>([]);
  const _menuRef = useRef<Menu>(null);

  useEffect(() => {
    setUserName(ClientUserService.displayName(_currentUser?.user));
  }, [_currentUser.user]);

  const handleLogout = (e: any) => {
    opProfile.current?.hide();
  };

  const doNavigation = (e: MenuItemCommandEvent) => {
    let existing = document.querySelectorAll(".item-selected");
    if (existing) {
      existing.forEach((el) => {
        el.classList.toggle("item-selected");
      });
    }
    e.originalEvent.currentTarget.classList.toggle("item-selected");
    e.originalEvent.preventDefault();
    let action = e.item as HeaderAction;
    if (action.path) navigate(action.path);
  };

  const linkProc = (items: HeaderAction[]) => {
    for (let i = 0; i < items.length; i++) {
      const a = { ...items[i] };
      if (a.path) {
        a.command = doNavigation;
        a.url = `${window.location.origin}${a.path}`;
        items[i] = a;
      }
      if (a.items && a.items.length > 0) {
        a.items.forEach((eachItem: HeaderAction) => {
          if (eachItem.path) {
            eachItem.url = `${window.location.origin}${eachItem.path}`;
          }
        });
        linkProc(a.items as HeaderAction[]);
      }
    }
  };

  const configureAdminMenu = () => {
    let menu = [
      {
        icon: iconTypeToPrimeIcon(IconTypes.Settings),
        path: "/settings",
        label: translate("SETTINGS"),
      },
      {
        icon: iconTypeToPrimeIcon(IconTypes.Sync),
        path: "/data-browse",
        label: "Data Browser",
      },
      {
        icon: iconTypeToPrimeIcon(IconTypes.New),
        path: "/map",
        label: "Map Fields",
      },
      {
        icon: iconTypeToPrimeIcon(IconTypes.Save),
        path: "/add",
        label: "Add Data",
      },
      {
        icon: iconTypeToPrimeIcon(IconTypes.Sync),
        path: "/profile",
        label: "Profile Data",
      }
    ];
    linkProc(menu);
    setMenuItems(menu);
  };

  useEffect(() => {
    if (_currentUser.user) {
      configureAdminMenu();
    }
  }, [_currentUser.user]);

  const hasRole = (roles: string[]) => {
    let user = _currentUser.user;
    let roleValid = false;
    if (user && roles.length > 0) {
      roleValid = false;
      let userRoles = user.roles as string[] as string[];
      if (userRoles) {
        for (let i = 0; i < roles.length; i++) {
          const role = roles[i];
          if (userRoles.includes(role)) {
            roleValid = true;
            break;
          }
        }
      }
    }
    if (user && roles.length === 0) roleValid = true;
    return roleValid;
  };
  const goHome = () => {
    navigate("/");
  };

  return (
    <div>
      {_currentUser.user ? (
        <div className="header-bar" style={{ display: "block" }}>
          <div className="header-left-block">
            <a onClick={goHome}><img src="ivanhoe.png" width="500" /></a>
          </div>

          <div className="header-right-block">
            <Menu
              model={_menuItems}
              popup
              className="structure-menu"
              ref={_menuRef}
            />
            <Button
              title={_userName}
              id="profile-container-button"
              icon={iconTypeToPrimeIcon(IconTypes.User)}
              className="user-profile-button p-button-rounded p-button-text"
              onClick={(e) => opProfile.current?.toggle(e, null)}
            ></Button>

           
              <Button
                icon={iconTypeToPrimeIcon(IconTypes.Menu)}
                onClick={(e) => _menuRef.current?.toggle(e)}
                className="admin-menu-button p-button-ronded p-button-text"
              />
            
          </div>

          <OverlayPanel
            ref={opProfile}
            showCloseIcon
            id="note_overlay_panel"
            className="profile"
            style={{ width: "300px" }}
          >
            <UserProfileComponent
              name={_userName}
              onLogout={handleLogout}
              onClose={() => opProfile.current?.hide()}
            />
          </OverlayPanel>
        </div>
      ) : undefined}
    </div>
  );
}
