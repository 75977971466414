import { WorkflowSessionNode } from "@liminil/integrator-sdk";
import { createSlice } from "@reduxjs/toolkit";
import { parseISO, parseJSON } from "date-fns";
import { SessionMessage } from "../types/session-message.types";

export interface SessionNode {
    sessionId: string;
    userId: string;
    workflowId: string;
    nodeName: string;
    nodeId: string;
    status: string;
    progress?: number;
}



export interface SessionState {
    messages: SessionMessage[];
    nodes: SessionNode[];
}

export const initSessionState: SessionState = {

    messages: new Array<SessionMessage>(),
    nodes: new Array<SessionNode>()
}

const sessionSlice = createSlice({
    name: 'session',
    initialState: initSessionState,
    reducers: {
        addMessage(state, action) {
            let messages = [...state.messages];
           let time = parseJSON(action.payload.timeStamp)
            let message = {...action.payload, tileShown: false, toastShown: false, time: time.getTime(), timeStamp: undefined};
            messages.push(message);
           
                      
            state.messages = messages.sort((a, b) => b.time - a.time);
            if (message.nodeId) {
                let nodes = state.nodes;
                let index = nodes.findIndex(x => x.nodeId === action.payload.nodeId);
                if (index === -1) {
                    nodes.push(message)
                } else {
                    nodes[index] = message;
                }
                state.nodes = nodes;
            }
            
        },
        markToastMessage(state, action) {
            let messages = state.messages;
            let message = messages.find(x => x.messageId === action.payload);
            if (message) {
                message.toastShown = true;
            }
            state.messages = messages;
        },

        markTileMessage(state, action) {
            let messages = state.messages;
            let message = messages.find(x => x.messageId === action.payload);
            if (message) {
                message.tileShown = true;
            }
            state.messages = messages;
        },
        removeMessages(state, action) {
           // let messages = state.messages.filter(x => x.sessionId !== action.payload);
          //  let nodes = state.nodes.filter(x => x.sessionId !== action.payload);
            state.messages = new Array<SessionMessage>();
            state.nodes = new Array<SessionNode>();
        }
    }


});


export const { addMessage, markToastMessage, markTileMessage, removeMessages } = sessionSlice.actions
export default sessionSlice.reducer