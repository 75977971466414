
import io, { Socket } from 'socket.io-client';
import { SettingsService } from '../settings.service';
import { store } from '../../redux/store';
import { UserCache } from '../caches';



interface SocketItem {
    socket: Socket | null;
    name: string;
}

interface ConnectionInfo {
    path: string;
    url: string
}

export class SocketService {



    private static _instances: SocketItem[] = new Array<SocketItem>();

    private static getInstance(name: string): SocketItem | undefined {
        return SocketService._instances.find(x => x.name === name);
    }

    private static getConnectionInfo(module: string): ConnectionInfo {
        let settingsService = new SettingsService();
        switch (module.toLowerCase()) {
            case 'workflow':
                return {
                    url: settingsService.wkfSocket,
                    path: settingsService.production ? `/socket/wkf/session` : "/socket/wkf/session"
                }

            case 'auth':
                return {
                    url: settingsService.authSocket,
                    path: settingsService.production ? `/socket/ath/user` : "/user"
                }
            default:
                return {
                    url: settingsService.wkfSocket,
                    path: settingsService.production ? `/socket/wkf/session` : "/socket/wkf/session"
                }
        }


    }

    public static getSocket(name: string): Socket | null {
        let instance = SocketService.getInstance(name);
        if (!instance || instance.socket === null) {
            let connectionInfo = SocketService.getConnectionInfo(name);
            let currentUser = store.getState().currentUser;
            let user = currentUser.user;
            let customer = user?.currentCustomer

            let options = {
                query: { token: UserCache.idToken, userId: user?._id, customerId: customer?._id },
                path: connectionInfo.path
            };

            let socket = io(connectionInfo.url, options);
            instance = {
                name: name,
                socket: socket
            };
            SocketService._instances.push(instance)
        }

        return instance.socket

    }

    public static reset(name: string) {
        let instance = SocketService.getInstance(name);
        if (instance) {
            let socket = instance.socket;
            if (socket) {
                socket.disconnect();
                instance.socket = null
            }
        }
    }

}


