import React, { useEffect, useRef, useState } from 'react';
import './App.scss';
import '@fortawesome/fontawesome-free/css/all.css';
import 'primeflex/primeflex.css';
import 'primeicons/primeicons.css';
import 'primereact/resources/primereact.css';
import 'primereact/resources/themes/saga-blue/theme.css';
import AppHeader from './components/app-header/app-header';
import io, { Socket } from 'socket.io-client';
import loadable from '@loadable/component';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { CurrentUserState, getCurrentUser } from './redux/c-user.slice';
import RequireAuth from './components/authentication/auth-validation';
import { useSelector } from 'react-redux';
import { AppState, store } from './redux/store';
import { WikiColorInterface, updateTheme } from './common/utils/style-utils';
import Footer from './components/footer/footer';
import { getVersion } from './redux/settings-slice';
import { IntegratorRole } from '@liminil/partner-types';
import { NavBarState, NavPageMode, setLayoutMode, setSideMenu, setSideMenuOpen } from './redux/nav-bar-slice';
import MessageBox from './components/message-box';
import { Sidebar } from 'primereact/sidebar';
// import AiQueryGenerator from '@liminil/ui-components';
import { Toast } from 'primereact/toast';

import ChatApp from './components/chat';
import { UserCache } from './services/caches';
import { quickSocketIntialize, sessionInitSockets } from './services/socket/session-socket.service';
const Home = loadable(() => import('./routes/home'));
const Signin = loadable(() => import('./routes/signin'));
const RootView = loadable(() => import('./routes/root'));
const SettinsView = loadable(() => import('./routes/settings'));
const ReaderView = loadable(() => import('./routes/reader/wh-reader'));
const DataBrowserView = loadable(() => import('./routes/browse'))
const MapFieldsView = loadable(() => import('./routes/map'))
const AddView = loadable(() => import('./routes/add'))
const ProfileView = loadable(() => import('./routes/profile'))
const DataEntry = loadable(() => import('./routes/entry'))
const JDEView = loadable(() => import('./routes/jde'))
const TerminationReport = loadable(() => import('./routes/termination'))

const mql = window.matchMedia(`(min-width: 800px)`);

function App() {

  const dispatch = useDispatch<any>();
  const _currentUser = useSelector<AppState>(state => state.currentUser) as CurrentUserState;

  const colors: WikiColorInterface = {
    color1: "#ffffff", // header background
    color2: "#ff0000", // font color
    color3: "#f5f5f5", // active color
    color4: "#000000", // active font color
    color5: "#c1c1c1", //hover color
    color6: "#c1c1c1", // menu hover
    color7: "#000000", // element color
    color8: "#ffffff",
    color9: "#D0A946",
    color10: "#000000",
    color11: "#c1c1c1",
    color12: "#000000"
  }

  useEffect(() => {
    if (_currentUser?.user) {
      updateTheme(colors);
    }
  }, [colors])


  useEffect(() => {
    dispatch(getCurrentUser());
    dispatch(getVersion());
    const sck = quickSocketIntialize();
    sck?.on('connect', () => {
        console.log('connected');
    })
    sck?.on('session', (msg: any) => {
      console.log(msg);
      if(msg.status == 'complete'){
        toast.current?.show({severity:'success', summary: `${msg.workflowName}`, detail:`${msg.workflowName} is complete.`, life: 3000});
      }
    })
  }, []);


  useEffect(() => {

    mql.onchange = (ev => {

      if (ev.matches) {
        dispatch(setLayoutMode("full"))
      } else {
        dispatch(setLayoutMode("mobile"))
      }
    });

    mql.addEventListener("error", (e) => {
      console.log(e);
    })

    if (mql.matches) {
      dispatch(setLayoutMode("full"))
    } else {
      dispatch(setLayoutMode("mobile"))
    }

  }, [])
  document.addEventListener('keydown', (e) => {
    if(e.ctrlKey && e.key === 'a'){
      setVisibleChat(true);
    }
  });





  const [visibleChat, setVisibleChat] = React.useState<boolean>(false);
  const toast = useRef<Toast>(null);

  return (
    <>
      <div className='app-container'>
        <Router>

          <div className='nav-menu-container'>
            <AppHeader />
          </div>
          <MessageBox />
          <div id="main-container" className='main-container'>

            <Routes>
              <Route path="/" element={<RootView />} />
              <Route path="/signin" element={<Signin />} />
              <Route path="/reader" element={<RequireAuth><ReaderView /></RequireAuth>} />\
              <Route path="/add" element={<RequireAuth><AddView /></RequireAuth>} />
              <Route path="/entry" element={<RequireAuth><DataEntry /></RequireAuth>} />
              <Route path="/jde" element={<RequireAuth><JDEView /></RequireAuth>} />
              <Route path="/home" element={<RequireAuth><Home /></RequireAuth>} />
              <Route path="/map" element={<RequireAuth><MapFieldsView /></RequireAuth>} />
              <Route path="/profile" element={<RequireAuth><ProfileView /></RequireAuth>} />
              <Route path="/data-browse" element={<RequireAuth><DataBrowserView /></RequireAuth>} />
              <Route path="/settings" element={<RequireAuth><SettinsView /></RequireAuth>} />
              <Route path="/termination" element={<RequireAuth><TerminationReport /></RequireAuth>} />
            </Routes>

          </div>
          <Footer />
        </Router>
        <Sidebar  visible={visibleChat} style={{height: 500, width: 700, marginLeft: '45%', padding: 0}} position="bottom"  onHide={() => setVisibleChat(false)}>
          <ChatApp />
        </Sidebar>
        <Toast ref={toast} />
      </div>
    </>
  );
}

export default App;