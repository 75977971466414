import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { AppState, store } from "./store";
import { ClientUserService } from "../services/modules/users/client-user.service";
import { IntegratorUser } from "@liminil/partner-types";
import translate from "../common/translate";
import { MessageType, showMessageBox } from "./message-box.slice";


export interface CurrentUserState {
    readonly user: IntegratorUser | null;
    readonly busy: boolean;
}

export const initCurrentUserState: CurrentUserState = {
    user: null,
    busy: false
}

export const getCurrentUser = createAsyncThunk(
    'user/getCurrentUser',
    async (arg, { getState }) => {
        const state = getState() as AppState;
        let currentUser = state.currentUser;
        if (!currentUser.user) {
            let service = new ClientUserService();
            const user = await service.getMe();
            return user;
        }
        return currentUser.user
    }
)


export const tokenDataUpdate = createAsyncThunk(
    'user/tokenDataUpdate',
    async (data: IntegratorUser, { getState }) => {
        const state = getState() as AppState;
        if (!state.currentUser.busy) {
            let currentCustomer = state.currentUser.user?.currentCustomer;
            if (currentCustomer?._id !== data.currentCustomer?._id) {

                let message = translate("CURRENT_CUSTOMER_CHANGED", data.currentCustomer);
                store.dispatch(showMessageBox({ message: message, messageType: MessageType.Warning, duration: 5000, position: "top-center", sticky: true }))
                return data;

            }
        }
        return state.currentUser.user;


    }
)


const currentUserModelSlice = createSlice({
    name: 'current-user-model',
    initialState: initCurrentUserState,
    reducers: {
        setCurrentUser(state, action) {
            state.user = action.payload
        }
    },
    extraReducers: (builder) => {
        builder.addCase(getCurrentUser.fulfilled, (state, action) => {
            state.user = action.payload
        })
    }
})

export const { setCurrentUser } = currentUserModelSlice.actions;
export default currentUserModelSlice.reducer;