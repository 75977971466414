import { reviveDateTime } from "../../common/date-utils";
import { addMessage } from "../../redux/session-slice";
import { store } from "../../redux/store";
import { JwtAuthService } from "../authentication";
import { SocketService } from "./socket.service";

let sessionSocketInitialized = false;

export function quickSocketIntialize() {
    if (!sessionSocketInitialized && JwtAuthService.isAuthenticated()) {
        return SocketService.getSocket("Workflow");
    }
    return null;
}
export function sessionInitSockets() {

    if (!sessionSocketInitialized && JwtAuthService.isAuthenticated()) {
        let _socket = SocketService.getSocket("Workflow");
        if (_socket) {
            _socket.on('session', async (data: any) => {
                if (typeof data.timeStamp === 'string') {
                    
                    data.timeStamp = reviveDateTime("timeStamp", data.timeStamp);
                }

                store.dispatch(addMessage(data))            
            });


            sessionSocketInitialized = true;
        }
    }
}

export function sessionResetSockets() {
    sessionSocketInitialized = false;
    SocketService.reset('Workflow');
}

