import { Middleware, configureStore } from "@reduxjs/toolkit";
import settingsReducer, { SettingsState, initSettingsState } from "./settings-slice";
import currentUserReducer, { CurrentUserState, initCurrentUserState } from "./c-user.slice";
import navbarReducer, { NavBarState, initNavBarState } from "./nav-bar-slice";
import messageBoxReducer, { MessageBoxState, initMessageBoxState } from "./message-box.slice";
import { datesToString } from "../common/date-utils";
import thunkMiddleware from 'redux-thunk';
import { SessionState, initSessionState } from "./session-slice";

export interface AppState {
    settings: SettingsState;
    currentUser: CurrentUserState;
    navbar: NavBarState;
    messageBox: MessageBoxState;
    session: SessionState;

}


export const initializeAppState: AppState = {
    settings: initSettingsState,
    currentUser: initCurrentUserState,
    navbar: initNavBarState,
    messageBox: initMessageBoxState,
    session: initSessionState
}

export const dateMiddleware: Middleware = api => next => action => {
    
    if (action.payload) {
      action.payload = datesToString(action.payload)
    }
    if (action.meta) {
      action.meta = datesToString(action.payload)
    }
    return next(action);
  };

export const store = configureStore({
    reducer: {
        settings: settingsReducer,
        currentUser: currentUserReducer,
        navbar: navbarReducer,
        messageBox: messageBoxReducer
    },
    middleware: (getDefaultMiddleware) =>
  getDefaultMiddleware({
    serializableCheck: {
      ignoredActionPaths: [],
      ignoredPaths: []
      
    }
  }).concat(thunkMiddleware).concat(dateMiddleware)
})